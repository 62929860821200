import LocomotiveScroll from 'locomotive-scroll';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";


document.addEventListener('DOMContentLoaded', () => {

  gsap.registerPlugin(ScrollTrigger);
  const pageContainer = document.querySelector("[data-scroll-container]");

  const scroller = new LocomotiveScroll({
    el: pageContainer,
    smooth: false,
    getDirection: true,
    reloadOnContextChange:true,
    mobile: {
      breakpoint: 0,

    },
    tablet: {
      breakpoint: 0,
      smooth: false,
    },
  });

  ScrollTrigger.matchMedia({
    "(min-width: 800px)": function () {

      scroller.on("scroll", ScrollTrigger.update);
      ScrollTrigger.scrollerProxy(pageContainer, {
        scrollTop(value) {
          return arguments.length
            ? scroller.scrollTo(value, 0, 0)
            : scroller.scroll.instance.scroll.y;
        },
        getBoundingClientRect() {
          return {
            left: 0,
            top: 0,
            width: window.innerWidth,
            height: window.innerHeight
          };
        },
        pinType: pageContainer.style.transform ? "transform" : "fixed"
      });


      ////////////////////////////////////
      ////////////////////////////////////
      window.addEventListener("load", function () {
        let pinBoxes = document.querySelectorAll(".pin-wrap > *");
        let pinWrap = document.querySelector(".pin-wrap");
        let pinWrapWidth = pinWrap.offsetWidth;
        let horizontalScrollLength = pinWrapWidth - window.innerWidth;

        // Pinning and horizontal scrolling

        gsap.to(".pin-wrap", {
          scrollTrigger: {
            scroller: pageContainer, //locomotive-scroll
            scrub: true,
            trigger: "#sectionPin",
            pin: true,
            // anticipatePin: 1,
            start: "top top",
            end: pinWrapWidth
          },
          x: -horizontalScrollLength,
          ease: "none"
        });

        ScrollTrigger.addEventListener("refresh", () => scroller.update()); //locomotive-scroll

        ScrollTrigger.refresh();

      });
    }
  })

  // animation forme parralax
  const parralaxes = document.querySelectorAll('[js-parralax]');
  addEventListener('scroll' , function() {
    let scrollOffset = window.scrollY;
    parralaxes.forEach(function(parralax) {
      parralax.style.transform = "translateY(-" + window.scrollY * 0.7  + "px)";    })
  })
});



